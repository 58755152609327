/** @format */

import OfferStyled from '../../styled/OfferStyled';
import RODO from '../../media/RODO.jpg';

const Rodo = () => {
  return (
    <div className='rodo'>
      <OfferStyled>
        <div>
          <h1>DOKUMENTACJA RODO</h1>
          <div
            className='image animation'
            style={{ backgroundImage: `url(${RODO})` }}
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>Szczegółowy audyt bezpieczeństwa.</li>
              <li>
                Analiza ryzyka i potencjalnego zagrożenia dla ochrony danych
                osobowych w organizacji.
              </li>
              <li>Opracowanie wewnętrznej dokumentacji.</li>
              <li>Wdrażanie procedury i polityki bezpieczeństwa.</li>
              <li>
                Organizowanie szkoleń dla pracowników oraz kadry zarządzającej.
              </li>
            </ol>
          </div>
        </div>
      </OfferStyled>
    </div>
  );
};

export default Rodo;
