/** @format */

import styled from 'styled-components';

const SliderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 2;
  opacity: 0.7;
  span {
    position: absolute;
    top: 3vh;
    color: white;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
`;
export default SliderStyled;
