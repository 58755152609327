/** @format */

import styled from 'styled-components';

const Footer = styled.footer`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: var(--darkcolor);
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    p {
      text-align: center;
      cursor: pointer;
    }
  }
`;
export default Footer;
