/** @format */
import FooterStyled from '../styled/FooterStyled';
import logoGorgo from '../media/logoGorgo.ico';

const Footer = () => {
  let data = new Date();

  const goToGorgo = () => {
    window.open('https://gorgo-stronyinternetowe.vercel.app/');
  };
  return (
    <FooterStyled>
      <div>
        <p>
          {' '}
          © {data.getFullYear()} Kompleksowe Zarządzanie Nieruchomościami Daniel
          Bogdanowicz
        </p>
        <p onClick={goToGorgo}>
          Wykonawca strony /{' '}
          <img src={logoGorgo} alt='logo' style={{ width: '14px' }} /> ©
          gorgo-stronyinternetowe.pl
        </p>
      </div>
    </FooterStyled>
  );
};

export default Footer;
