/** @format */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import img1 from '../media/Firpage.jpg';
import tomojdom from '../media/secpage.jpg';

import HomeStyled from '../styled/HomeStyled';

const images = [
  {
    src: img1,
    text: 'Kompleksowe Zarządzanie Nieruchomościami Daniel Bogdanowicz',
  },
  { src: tomojdom, button: 'e-kartoteka' },
];

const Home = () => {
  const [page, setPage] = useState(0);
  const [pageBack, setPageBack] = useState(false);

  useEffect(() => {
    const timerId = setInterval(() => handleSelectNext(), 7000);

    return () => clearInterval(timerId);
  });

  const handleSelectNext = () => {
    setPageBack(false);
    page === images.length - 1 ? setPage(0) : setPage(page + 1);
  };
  const handleSelectBack = () => {
    setPageBack(true);

    page === 0 ? setPage(images.length - 1) : setPage(page - 1);
  };

  const openCustomerPanel = () => {
    window.open('https://tomojdom.pl/', '_blank');
  };

  return (
    <HomeStyled>
      <div>
        <div className='before' onClick={handleSelectBack}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </div>

        <section>
          {images.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  page === index
                    ? pageBack
                      ? 'imageBack'
                      : 'image'
                    : pageBack
                    ? 'activeBack'
                    : 'active'
                }
                style={{ backgroundImage: `url(${item.src})` }}
              >
                {item.text ? (
                  <>
                    <h1>
                      Kompleksowe Zarządzanie Nieruchomościami
                      <br /> Daniel Bogdanowicz
                    </h1>
                  </>
                ) : null}
                {item.button ? (
                  <>
                    <button onClick={openCustomerPanel}>{item.button}</button>
                  </>
                ) : null}
              </div>
            );
          })}
        </section>
        <div className='next' onClick={handleSelectNext}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </div>
      </div>
    </HomeStyled>
  );
};

export default Home;
