/** @format */

import React, { useContext } from 'react';
import theContext from './theContext';
import data from './Data';
import { Link } from 'react-router-dom';

const Menu = () => {
  const { isOpen, setIsOpen } = useContext(theContext);
  return <>{listItem(isOpen, setIsOpen)}</>;
};

const listItem = (isOpen, setIsOpen) =>
  data.map((d) => {
    return (
      <li key={d.name}>
        <Link to={d.section} onClick={() => setIsOpen(!isOpen)}>
          {d.name.toUpperCase()}
        </Link>
      </li>
    );
  });

export default Menu;
