/** @format */

import styled from 'styled-components';

const ReferencesStyled = styled.div`
  max-width: 1024px;
  min-height: 100vh;
  margin-top: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin: 8px;
    width: 200px;
    height: 282px;
    object-fit: cover;
    cursor: pointer;
  }
`;
export default ReferencesStyled;
