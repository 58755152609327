/** @format */

import styled from 'styled-components';

const ContactStyled = styled.div`
  /* główny kontener */
  display: flex;
  justify-content: center;
  width: 100vw;
  animation: transitionIn 1s;

  /* podkontener */
  div {
    max-width: 1024px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    .card {
      max-width: 95%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 16px;
      padding: 16px;
      box-shadow: 0px 0px 29px -3px rgba(123, 186, 88, 0.52);
    }

    @media (max-width: 800px) {
      max-width: 95%;
    }
  }

  h1 {
    margin-top: 56px;
    text-align: center;
  }

  @keyframes transitionIn {
    from {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
  form {
    box-shadow: 0px 0px 29px -3px rgba(123, 186, 88, 0.52);
    margin: 16px;
    padding: 16px;
    max-width: 95%;

    .send {
      position: relative;
      left: 50%;
      transform: translate(-50%);
      width: auto;
      height: auto;
      background-color: var(--bgcblue);
      color: var(--maincolor);
      text-align: center;
      font-weight: bold;
      border-radius: 15px;
      padding: 8px 16px;
    }

    div span {
      color: red;
    }
    h2 {
      text-align: center;
    }
    input,
    textarea {
      width: 100%;
      padding: 10px;
      height: 24px;
      margin-bottom: 12px;
    }
    textarea {
      height: 72px;
    }

    .checkbox {
      max-width: 95vw;

      input {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        margin-bottom: 4px;
        display: inline-block;
      }
      label {
        font-size: 12px;
        margin: 12px 0px;
      }
    }
  }
`;

export default ContactStyled;
