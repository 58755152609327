/** @format */
import OfferStyled from '../../styled/OfferStyled';
import PosSpad from '../../media/PosSpad.jpg';

const Heritage = () => {
  return (
    <div className='heritage'>
      <OfferStyled>
        <div>
          <h1>POSTĘPOWANIE SPADKOWE</h1>
          <div
            className='image animation'
            style={{ backgroundImage: `url(${PosSpad})` }}
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>
                Prowadzenie postępowania spadkowego (sądowe / notarialne).
              </li>
              <li>
                Prowadzenie postępowania o dział spadku (sądowe / notarialne).
              </li>
              <li>Poszukiwanie majątku po spadkodawcy.</li>
              <li>Prowadzenie postępowań o roszczenie o zachowek.</li>
            </ol>
          </div>
        </div>
      </OfferStyled>
    </div>
  );
};

export default Heritage;
