/** @format */
import { useState } from 'react';
import AboutStyled from '../styled/AboutStyled';
// import AboutCompany from './About/AboutCompany';
import Certificates from './About/Certificates';
import References from './About/References';

// const FIRMIE = 'O FIRMIE';
const REFERENCJE = 'REFERENCJE';
const CERTYFIKATY = 'CERTYFIKATY';

const About = () => {
  const [page, setPage] = useState(REFERENCJE);

  const handleClick = (e) => {
    setPage(e.target.innerText);
  };

  const getSection = () => {
    switch (page) {
      // case FIRMIE:
      //   return <AboutCompany />;
      case REFERENCJE:
        return <References />;
      case CERTYFIKATY:
        return <Certificates />;
      default:
        console.log('ok switch');
    }
  };

  return (
    <AboutStyled>
      <div>
        {list.map((d) => {
          const active = page === d;
          return (
            <button
              style={{ width: '250px' }}
              key={d}
              onClick={handleClick}
              className={active ? 'active' : null}
            >
              {d}
            </button>
          );
        })}
      </div>
      {getSection()}
    </AboutStyled>
  );
};

const list = [REFERENCJE, CERTYFIKATY];

export default About;
