/** @format */
import React, { useEffect } from 'react';
import ContactStyled from '../styled/ContactStyled';

const Rodo = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <ContactStyled>
      <div>
        <div className='card' style={{ textAlign: 'left' }}>
          <h2>KLAUZULA INFORMACYJNA RODO</h2>
          <p>
            Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego Rozporządzenia Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych) – zwanym RODO, informuję, że:
          </p>
          <ol type='1'>
            <li>
              Administratorem Państwa danych osobowych jest Kompleksowe
              Zarządzanie Nieruchomościami Daniel Bogdanowicz z siedzibą w
              Wałbrzychu.
            </li>
            <li>
              Dane kontaktowe: telefon 536-122-206, e-mail:
              biuro@nieruchomosci-bogdanowicz.pl
            </li>
            <li>
              Państwa dane osobowe będą przetwarzane na podstawie art. 6 ust. 1
              lit. a), c) i f) RODO, to znaczy na podstawie zgody osoby, której
              dane będą przetwarzane lub w celu wypełnienia obowiązku prawnego
              ciążącego na administratorze, realizacji zadań ustawowych i
              statutowych oraz do celów wynikających z prawnie uzasadnionych
              interesów realizowanych przez administratora lub przez stronę
              trzecią.
            </li>
            <li>
              Przetwarzanie Państwa danych osobowych odbywa się w szczególności
              na podstawie ustawy z dnia 24 czerwca 1994 roku o własności
              lokali, ustawy z dnia 21 sierpnia 1997 roku o gospodarce
              nieruchomościami, Kodeksu cywilnego, Kodeksu postępowania
              cywilnego, ustawy z dnia 6 marca 2018 roku Prawo przedsiębiorców.
            </li>
            <li>
              W trakcie przetwarzania Państwa dane osobowe mogą być ujawniane
              organom podatkowym, organom wymiaru sprawiedliwości, organowi
              nadzorczemu (Prezesowi Urzędu Ochrony Danych Osobowych) w oparciu
              o przepis prawa, osobom upoważnionym oraz podmiotom, które wykażą
              prawnie uzasadnione interesy.
            </li>
            <li>
              Państwa dane osobowe nie będą przekazywane do państw trzecich lub
              organizacji międzynarodowych.
            </li>
            <li>
              Państwa dane osobowe będą przechowywane przez okres niezbędny do
              realizacji obowiązku prawnego ciążącego na administratorze oraz
              dochodzenia lub obrony roszczeń.
            </li>
            <li>
              Posiadacie Państwo prawo do żądania dostępu do swoich danych
              osobowych, ich sprostowania, wniesienia sprzeciwu wobec
              przetwarzania danych oraz przenoszenia danych, a w przypadku
              wyrażenia zgody, do jej cofnięcia.
            </li>
            <li>
              Przysługuje Państwu prawo wniesienia skargi do Prezesa Urzędu
              Ochrony Danych Osobowych.
            </li>
            <li>
              Podanie Państwa danych osobowych jest wymogiem ustawowym i
              niezbędne do wypełnienia obowiązków prawnych ciążących na
              administratorze w przypadku nawiązania stosunku prawnego
              wywołującego skutki podatkowe.
            </li>
            <li>
              Administrator nie będzie podejmował wobec Państwa
              zautomatyzowanych decyzji, w tym decyzji będących wynikiem
              profilowania.
            </li>
          </ol>
        </div>
      </div>
    </ContactStyled>
  );
};

export default Rodo;
