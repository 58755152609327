/** @format */

import styled from 'styled-components';

const NavStyled = styled.ul`
  /* position: fixed;
  top: 0;
  left: 0; */
  margin-top: 16px;
  width: fit-content;
  display: flex;
  background-color: var(--bgcblue);

  list-style-type: none;
  z-index: 1;
  margin-left: 110px;
  border-radius: 16px;
  padding-inline-start: 16px;
  padding-inline-end: 32px;

  a {
    position: relative;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    text-align: center;
    font-weight: bold;
  }
  a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    text-decoration: underline;
    width: 100%;
    height: 100%;
    color: red;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export default NavStyled;
