/** @format */
import React, { useLayoutEffect } from 'react';
import Manage from './Manage';
import AboutStyled from '../../styled/AboutStyled';
import Rodo from './Rodo';
import Heritage from './Heritage';
import Lease from './Lease';
import Clean from './Clean';

const Offer = () => {
  useLayoutEffect(() => {
    const imagesElement = document.querySelectorAll('.image');

    const onScroll = () => {
      imagesElement.forEach((element) => {
        let elementTopToWindow = element.getBoundingClientRect().top;

        if (elementTopToWindow < window.innerHeight * 0.7) {
          element.classList.add('animation');
        } else if (elementTopToWindow > window.innerHeight * 0.8) {
          element.classList.remove('animation');
        }
      });
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useLayoutEffect(() => {
    const listsElement = document.querySelectorAll('li');

    const onScroll = () => {
      listsElement.forEach((element) => {
        let elementTopToWindow = element.getBoundingClientRect().top;

        if (elementTopToWindow < window.innerHeight * 0.9) {
          element.classList.add('animationol');
        } else if (elementTopToWindow > window.innerHeight * 0.9) {
          element.classList.remove('animationol');
        }
      });
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClick = (e) => {
    const btn = document.querySelectorAll('button');

    btn.forEach((element) => {
      element.classList.remove('active');
    });
    e.target.classList.add('active');

    if (e.target.innerText === 'POSTĘPOWANIA SPADKOWE') {
      document.querySelector('.heritage').scrollIntoView();
    } else if (e.target.innerText === 'ZARZĄDZANIE NIERUCHOMOŚCIAMI') {
      document.querySelector('.manage').scrollIntoView();
    } else if (e.target.innerText === 'DOKUMENTACJA RODO') {
      document.querySelector('.rodo').scrollIntoView();
    } else if (e.target.innerText === 'ZARZĄDZANIE NAJMEM PRYWATNYM') {
      document.querySelector('.lease').scrollIntoView();
    } else if (e.target.innerText === 'MYCIE KLATEK SCHODOWYCH') {
      document.querySelector('.clean').scrollIntoView();
    }
  };

  return (
    <AboutStyled>
      <div>
        <button className='active' onClick={handleClick}>
          ZARZĄDZANIE NIERUCHOMOŚCIAMI
        </button>
        <button onClick={handleClick}>ZARZĄDZANIE NAJMEM PRYWATNYM</button>
        <button onClick={handleClick}>POSTĘPOWANIA SPADKOWE</button>
        <button onClick={handleClick}>DOKUMENTACJA RODO</button>
        <button onClick={handleClick}>MYCIE KLATEK SCHODOWYCH</button>
      </div>
      <Manage />
      <Lease />
      <Heritage />
      <Rodo />
      <Clean />
    </AboutStyled>
  );
};

export default Offer;
