/** @format */

import styled from 'styled-components';

const MenuBurgerStyled = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 64px;
  margin: 0;
  top: 0;
  right: 0;

  border: 3px solid black;
  z-index: 2;

  background-color: var(--bgcblue);
  list-style-type: none;

  li {
    padding: 8px;
  }

  li:first-child {
    margin-top: 48px;
  }

  li:last-child {
    margin-bottom: 48px;
  }

  li:nth-child(even) {
    opacity: 1;
    transform: translateX(0%);
    animation: lievenRotation 0.3s ease-out;
  }

  @keyframes lievenRotation {
    0% {
      opacity: 0;
      transform: translateX(-25%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  li:nth-child(odd) {
    opacity: 1;
    transform: translateX(0%);
    animation: lioddRotation 0.3s ease-out;
  }
  @keyframes lioddRotation {
    0% {
      opacity: 0;
      transform: translateX(25%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  a {
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    text-align: center;
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export default MenuBurgerStyled;
