/** @format */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';
import MenuBurgerStyled from '../styled/MenuBurgerStyled';
import theContext from './theContext';

const Navburger = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { Provider } = theContext;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {isOpen ? (
        <div className='navburger'>
          <FontAwesomeIcon icon={faBars} onClick={handleClick} size='lg' />
        </div>
      ) : (
        <div className='navburgeropen'>
          <FontAwesomeIcon icon={faTimes} onClick={handleClick} size='lg' />
          <MenuBurgerStyled>
            <Provider value={{ isOpen, setIsOpen }}>
              <FontAwesomeIcon icon={faTimes} onClick={handleClick} size='lg' />
              <Menu />
              <li>
                <a href='http://tomojdom.pl/' target='_blank' rel='noreferrer'>
                  e-KARTOTEKA
                </a>
              </li>
            </Provider>
          </MenuBurgerStyled>
        </div>
      )}
    </div>
  );
};

export default Navburger;
