/** @format */
import ContactStyled from '../styled/ContactStyled';

const PriceList = () => {
  return (
    <ContactStyled>
      <div>
        <h1>CENNIK</h1>
        <div className='card'>
          <h3>
            {' '}
            Każda z usług wyceniana jest indywidualnie, szczególnie biorąc pod
            uwagę jasne i czytelne kryteria. Oferta zawsze jest dopasowana do
            Państwa potrzeb i wymagań. Zapraszam Państwa do kontaktu z firmą
            Kompleksowe Zarządzanie Nieruchomościami Daniel Bogdanowicz.
          </h3>
        </div>
      </div>
    </ContactStyled>
  );
};

export default PriceList;
