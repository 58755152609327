/** @format */
import React from 'react';
import MenuStandard from './MenuStandard';
import NavStyled from '../styled/NavStyled';

const Nav = () => {
  return (
    <>
      <NavStyled>
        <MenuStandard />
        <li>
          <a href='http://tomojdom.pl/' target='_blank' rel='noreferrer'>
            e-KARTOTEKA
          </a>
        </li>
      </NavStyled>
    </>
  );
};
export default Nav;
