/** @format */

import OfferStyled from '../../styled/OfferStyled';
import ObsFin from '../../media/ObsFin.jpg';
import ObsTech from '../../media/ObsTech.jpg';
import ObsPra from '../../media/ObsPra.jpg';
import DziKon from '../../media/DziKon.jpg';

const Manage = () => {
  return (
    <div className='manage'>
      <OfferStyled>
        <div>
          <h1>Obsługa finansowa</h1>
          <div
            className='image animation'
            style={{ backgroundImage: `url(${ObsFin})` }}
          ></div>
          <div className='list'>
            <ol type='1'>
              <li className='animationol'>
                Dokonywanie bieżących rozliczeń poprzez rachunek bankowy
                Wspólnoty Mieszkaniowej.
              </li>
              <li className='animationol'>
                Wydzielenie dla funduszu remontowego, na życzenie Wspólnoty
                Mieszkaniowej oddzielnego subkonta (jeśli do tej pory nie
                zostało wydzielone).
              </li>
              <li>
                Prowadzenie ewidencji pozaksięgowej kosztów zarządu
                nieruchomością wspólną, zaliczek uiszczanych na pokrycie tych
                kosztów, a także rozliczeń z innych tytułów na rzecz
                nieruchomości wspólnej – w zakresie i w sposób określony w
                odrębnej uchwale Wspólnoty Mieszkaniowej.
              </li>
              <li>
                Rozliczanie oraz windykacja poniższych płatności właścicieli
                lokali wnoszonych z góry do dnia 10-go każdego miesiąca (za
                dzień zapłaty uważa się dzień wpływu należności na rachunek
                bankowy Wspólnoty Mieszkaniowej):
                <ol type='a'>
                  <li>
                    zaliczek w poczet kosztów zarządu nieruchomością wspólną,
                  </li>
                  <li>
                    zaliczek za świadczenia dostarczane do lokali poszczególnych
                    właścicieli,
                  </li>
                  <li>
                    zaliczek na fundusz remontowy oraz innych ewentualnych
                    funduszy.
                  </li>
                </ol>
              </li>
              <li>
                Prowadzenie rozliczeń, włączając w szczególności sprawdzanie
                prawidłowości otrzymywanych od usługodawców rachunków i faktur
                oraz dokonywanie płatności na rzecz dostawców:
                <ol type='a'>
                  <li>usług w zakresie eksploatacji,</li>
                  <li>usług technicznych,</li>
                  <li>
                    innych usług świadczonych na rzecz Wspólnoty Mieszkaniowej.
                  </li>
                </ol>
              </li>
              <li>
                Zapewnienie terminowego opłacania podatków, ubezpieczeń i innych
                opłat publiczno-prawnych przypadających na nieruchomość wspólną,
                chyba że są one pokrywane bezpośrednio przez właścicieli
                poszczególnych lokali.
              </li>
              <li>Prowadzenie sprawozdawczości finansowej.</li>
              <li>
                Sporządzanie rocznych sprawozdań finansowych Wspólnoty
                Mieszkaniowej.
              </li>
              <li>
                Comiesięczne przekazywanie Zarządowi wydruków dot. płatności i
                poniesionych kosztów.
              </li>
            </ol>
          </div>
        </div>
      </OfferStyled>
      <OfferStyled>
        <div>
          <h1>Obsługa techniczna</h1>
          <div
            style={{ backgroundImage: `url(${ObsTech})` }}
            className='image'
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>
                Pomoc w przejęciu dokumentacji technicznej nieruchomości na
                podstawie protokołu zdawczo-odbiorczego od poprzedniego
                zarządcy.
              </li>
              <li>
                Przechowywanie oraz bieżące prowadzenie dokumentacji technicznej
                nieruchomości, w tym książki obiektu budowlanego.{' '}
              </li>
              <li>
                Zapewnienie prawidłowego użytkowania budynku i lokali oraz
                instalacji i urządzeń wentylacyjnych, instalacji ciepłej wody,
                instalacji wodociągowej i kanalizacyjnej, instalacji i urządzeń
                centralnego ogrzewania, instalacji i urządzeń gazowych,
                instalacji elektrycznej, instalacji piorunochronowej, kanałów i
                przewodów spalinowych oraz dymowych, wewnętrznych urządzeń do
                usuwania odpadów i nieczystości stałych, instalacji domofonowej,
                dźwigów osobowych, separatorów, systemów oddymiania kontroli
                czystości powietrza, systemów dostępu oraz innych instalacji
                zamontowanych na koszt Wspólnoty Mieszkaniowej, poprzez
                wyszukanie zewnętrznych podmiotów świadczących w/w usługi.
              </li>
              <li>
                Występowanie do właścicieli lokali o zezwolenie na wstęp do
                lokalu, ilekroć jest to niezbędne do przeprowadzenia
                konserwacji, remontu albo usunięcia awarii w nieruchomości
                wspólnej, a także w celu wyposażenia budynku, jego części lub
                innych lokali w dodatkowe instalacje.
              </li>
              <li>
                Zapewnienie bieżącej i nieprzerwanej obsługi technicznej
                nieruchomości poprzez wyszukiwanie podwykonawców, negocjowanie
                warunków zawieranych umów i kontrolę ich wykonania w zakresie:
                <ol type='a'>
                  <li>
                    kontroli technicznych i okresowych przeglądów nieruchomości
                    i urządzeń stanowiących jej wyposażenie,
                  </li>
                  <li>
                    oceny stanu technicznego oraz wyznaczenia i oszacowania
                    wartości niezbędnych działań inwestycyjnych (remontowych i
                    modernizacyjnych),
                  </li>
                  <li>
                    bieżących napraw i konserwacji nieruchomości wspólnej, a w
                    szczególności dokonywania napraw budynku i jego pomieszczeń
                    wspólnych oraz urządzeń technicznych umożliwiających
                    właścicielom lokali korzystanie z ogrzewania lokalu, ciepłej
                    i zimnej wody, gazu, domofonu, zbiorczej anteny
                    telewizyjnej, śmietnika i innych urządzeń należących do
                    wyposażenia nieruchomości wspólnej,
                  </li>
                  <li> remontów i modernizacji nieruchomości wspólnej,</li>
                  <li>zabezpieczania awarii na nieruchomości wspólnej,</li>
                  <li>
                    egzekwowanie praw z tytułu gwarancji oraz rękojmi. Ciągły
                    kontakt z Wykonawcami,
                  </li>
                  <li>
                    kontrolowanie terminowości wykonywania przez Wykonawców
                    gwarancyjnych prac naprawczych oraz remontowych.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </OfferStyled>
      <OfferStyled>
        <div>
          <h1>Obsługa prawna</h1>
          <div
            style={{ backgroundImage: `url(${ObsPra})` }}
            className='image'
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>
                Sporządzanie projektów umów dotyczących obsługi eksploatacyjnej
                oraz technicznej nieruchomości.
              </li>
              <li>
                Opracowywanie projektów uchwał na potrzeby Wspólnoty
                Mieszkaniowej.
              </li>
              <li>
                Prowadzenie windykacji należności (monitowanie płatności,
                przygotowywanie wezwań do zapłaty, przygotowywanie pozwów w
                postępowaniu upominawczym i występowanie do sądu).
              </li>
              <li>
                Prowadzenie spraw związanych z usuwaniem przez Wykonawców
                usterek gwarancyjnych.{' '}
              </li>
              <li>
                Na podstawie zlecenia, obsługa procesów sądowych dotyczących
                sporów natury prawnej.
              </li>
              <li>
                Wpisywanie osób nieregulujących zobowiązań względem Wspólnoty
                Mieszkaniowej na listę Krajowego Rejestru Dłużników.
              </li>
            </ol>
          </div>
        </div>
      </OfferStyled>
      <OfferStyled>
        <div>
          <h1>Działania kontrolne</h1>
          <div
            style={{ backgroundImage: `url(${DziKon})` }}
            className='image'
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>
                Obowiązujących umów na dostawę usług komunalnych: wody, ciepła,
                energii elektrycznej, gazu, odprowadzania ścieków oraz wywozu
                nieczystości stałych i innych umów niezbędnych do prawidłowego
                funkcjonowania nieruchomości.
              </li>
              <li>
                Utrzymania należytego porządku i czystości pomieszczeń i
                urządzeń budynku służących do wspólnego użytku właścicieli
                lokali w tym również hal garażowych.
              </li>
              <li>
                Utrzymania należytego porządku i czystości terenu zewnętrznego
                należącego do Wspólnoty Mieszkaniowej, ciągów pieszych oraz
                jezdnych i innych terenów, jeżeli obowiązek taki wynika z
                zawartych umów lub obowiązujących przepisów.
              </li>
              <li>
                Usuwania awarii, wykonywania prac i remontów przez zewnętrznych
                usługodawców.
              </li>
              <li> Ochrony lub dozoru nieruchomości wspólnej.</li>
              <li>
                Obsługi oraz konserwacji dźwigów osobowych, separatorów,
                przepompowni ścieków, systemów przeciw pożarowych, wentylacji
                mechanicznej, systemów uzdatniania i kontroli powietrza w halach
                garażowych i innych, stanowiących część wspólną nieruchomości.
              </li>
              <li>
                Posiadania ubezpieczenia nieruchomości wspólnej od ognia i
                innych zdarzeń losowych oraz od odpowiedzialności cywilnej.
              </li>
              <li>Terminowości przeglądów technicznych.</li>
              <li>
                Usuwania usterek oraz wad w terminie gwarancji lub rękojmi.
              </li>
            </ol>
          </div>
        </div>
      </OfferStyled>
    </div>
  );
};

export default Manage;
