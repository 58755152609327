/** @format */

const data = [
  { name: 'start', section: '/' },
  { name: 'o nas', section: '/onas' },
  { name: 'oferta', section: '/oferta' },
  { name: 'cennik', section: '/cennik' },
  { name: 'kontakt', section: '/kontakt' },
];

export default data;
