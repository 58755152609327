/** @format */

import { useLayoutEffect, useState } from 'react';
import Navburger from './NavBurger';
import Nav from './Nav';
import LogoStyled from '../styled/LogoStyled';
import { Link } from 'react-router-dom';
const Navigation = () => {
  ShowWindowDimensions();
  return (
    <>
      <Link to='/'>
        <LogoStyled></LogoStyled>
      </Link>
      {ShowWindowDimensions() < 800 ? <Navburger /> : <Nav />}
    </>
  );
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function ShowWindowDimensions(props) {
  const [width] = useWindowSize();
  return width;
}

export default Navigation;
