/** @format */

import './App.css';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import Offer from './pages/Offer/Offer';
import Contact from './pages/Contact';
import PriceList from './pages/PriceList';
import Rodo from './pages/Rodo';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import Footer from './components/Footer';

function App() {
  return (
    <div className='App'>
      <Router>
        <nav>
          <Navigation />
        </nav>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/onas'>
            <About />
          </Route>
          <Route path='/oferta'>
            <Offer />
          </Route>
          <Route path='/cennik'>
            <PriceList />
          </Route>
          <Route path='/kontakt'>
            <Contact />
          </Route>
          <Route path='/rodo'>
            <Rodo />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
