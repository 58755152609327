/** @format */

import styled from 'styled-components';

const AboutStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-top: 64px;
  animation: transitionIn 0.5s;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  button {
    padding: 4px 48px;
    background-color: var(--bgcgreen);
    color: var(--maincolor);
    font-size: 24px;
    font-family: 'Roboto';
    border-radius: 15px;
    cursor: pointer;
    :focus {
      outline: 0;
    }
  }

  .active {
    color: var(--maincolor);
    background-color: var(--bgcblue);
    transition: 1s;
  }
  @keyframes transitionIn {
    from {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }

  .animation {
    animation: move 0.7s forwards;
  }
  @keyframes move {
    from {
      left: -10%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  .animationol {
    animation: moveol 0.7s forwards;
  }
  @keyframes moveol {
    from {
      left: 20%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  @media (max-width: 500px) {
    margin-top: 32px;
    button {
      font-size: 16px;
      padding: 4px 16px;
    }
  }
`;

export default AboutStyled;
