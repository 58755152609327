/** @format */
import styled from 'styled-components';

const HomeStyled = styled.div`
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  div {
    position: relative;
    max-width: 1024px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    h1 {
      position: absolute;
      bottom: 20%;
      display: inline;
      color: rgb(123, 186, 88);
      background-color: rgb(0, 0, 0, 0.5);
      padding: 24px;
      border-radius: 24px;
      max-width: 70%;
      text-align: center;
      font-weight: bold;
      user-select: none;
    }
    button {
      position: absolute;
      bottom: 20%;
      color: black;
      background-color: var(--bgcgreen);
      padding: 16px;
      border: 4px solid black;
      border-radius: 16px;
      font-size: 24px;
    }
  }
  .next,
  .before {
    color: white;
    font-size: 2rem;
  }
  section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    .image,
    .active,
    .activeBack,
    .imageBack {
      display: flex;
      flex-direction: column;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      animation: slideFrom 1s;
    }
    @keyframes slideFrom {
      from {
        left: -100%;
      }
      to {
        left: 0%;
      }
    }
    .active {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      animation: slideTo 1s;
    }
    @keyframes slideTo {
      from {
        left: 0%;
      }
      to {
        left: 100%;
      }
    }

    .imageBack {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      animation: slideFromBack 1s;
    }
    @keyframes slideFromBack {
      from {
        left: 100%;
      }
      to {
        left: 0%;
      }
    }

    .activeBack {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      animation: slideToBack 1s;
    }
    @keyframes slideToBack {
      from {
        left: 0%;
      }
      to {
        left: -100%;
      }
    }
  }

  .before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05);
    width: 10%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .next {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.05);
    width: 10%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default HomeStyled;
