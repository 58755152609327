/** @format */
import React from 'react';
import ContactStyled from '../styled/ContactStyled';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { FacebookOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Contact = () => {
  const { register, handleSubmit, errors } = useForm();

  function sendEmail() {
    const data = document.querySelector('form');

    emailjs
      .sendForm(
        'service_2mmykxe',
        'template_3zthlyl',
        data,
        'user_W7JRwGJioPZUXUlzirUBc'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Twój formularz został wysłany');
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const onSubmit = (e) => {
    console.log(e);

    e.Rodo === true && sendEmail();
  };

  return (
    <ContactStyled>
      <div>
        <div className='card'>
          <h2>
            Kompleksowe Zarządzanie Nieruchomościami <br />
            Daniel Bogdanowicz
          </h2>
          <p>
            <a href='tel:+48536122206'>536-122-206</a>
          </p>
          <p>
            <a href='mailto: biuro@nieruchomosci-bogdanowicz.pl'>
              biuro@nieruchomosci-bogdanowicz.pl
            </a>
          </p>
          <h3>Znajdż nas na Facebook'u</h3>
          <a
            href='https://www.facebook.com/Kompleksowe-Zarz%C4%85dzanie-Nieruchomo%C5%9Bciami-Daniel-Bogdanowicz-107366208125627'
            target='_blank'
            rel='noreferrer'
          >
            <FacebookOutlined style={{ color: 'blue', fontSize: '48px' }} />
          </a>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Napisz do nas</h2>
            <div>
              <input
                type='text'
                placeholder='Wpisz swoje imię...'
                name='LastName'
                ref={register({ required: true, maxLength: 20 })}
              />
              <span>
                {errors.LastName && 'To pole jest wymagane, wpisz swoje Imię.'}
              </span>
              <input
                type='text'
                placeholder='Wpisz swoje nazwisko...'
                name='Firstname'
                ref={register({ required: true, maxLength: 30 })}
              />
              <span>
                {errors.Firstname &&
                  'To pole jest wymagane, wpisz swoje Nazwisko.'}
              </span>
              <input
                type='text'
                placeholder='Wpisz swój adres Email...'
                name='Email'
                ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                rows='5'
              />
              <span>
                {errors.Email &&
                  'To pole jest wymagane, wpisz adres swojego adresu e-mail.'}
              </span>
              <input
                type='tel'
                placeholder='Wpisz swój numer telefonu...'
                name='Mobile'
                ref={register({ required: true, minLength: 9, maxLength: 15 })}
              />
              <span>
                {errors.Mobile &&
                  'To pole jest wymagane, wpisz numer telefonu.'}
              </span>
              <textarea
                type='text'
                placeholder='Wpisz swoje zapytanie...'
                name='Question'
                ref={register({ required: false })}
              />
            </div>
            <div className='checkbox'>
              <label>
                <input
                  type='checkbox'
                  name='Rodo'
                  ref={register({ required: true })}
                />
                <span>{errors.Rodo && 'To pole jest wymagane.'}</span>
                <sup>*(wymagane)</sup> Zaznaczając ten kwadrat, wyrażam zgodę na
                przetwarzanie moich danych osobowych zgodnie z RODO (ogólne
                rozporządzenie o ochronie danych – Rozporządzenie Parlamentu
                Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku)
                w związku z realizacją zgłoszenia. Podanie danych jest
                dobrowolne, ale niezbędne do przetworzenia zapytania.
                Potwierdzam, że zostałem/am poinformowany/a, że przysługuje mi
                prawo dostępu do swoich danych, ich sprostowania, usunięcia lub
                ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec
                przetwarzania, a także prawo do przenoszenia danych oraz prawo
                do złożenia oświadczenia o cofnięciu każdej wyrażonej zgody w
                każdym czasie.
              </label>
            </div>
            <div className='checkbox'>
              <label>
                <input
                  type='checkbox'
                  name='InformacjeHandlowe'
                  ref={register({ required: false })}
                />
                Zaznaczając ten kwadrat, wyrażam zgodę na przesyłanie przez
                Kompleksowe Zarządzanie Nieruchomościami Daniel Bogdanowicz
                informacji handlowych i marketingowych dotyczących oferty firmy
                oraz podmiotów powiązanych za pomocą środków komunikacji
                elektronicznej zgodnie z art. 10 ustawy z dnia 18 lipca 2002
                roku o świadczeniu usług drogą elektroniczną (tekst jednolity
                Dz. U. z 2020 roku poz. 344) na wskazany w formularzu
                kontaktowym adres poczty elektronicznej.
              </label>
            </div>

            <div className='checkbox'>
              <label>
                <input
                  type='checkbox'
                  name='OfertaFirmy'
                  ref={register({ required: false })}
                />
                Zaznaczając ten kwadrat, wyrażam zgodę na przekazywanie przez
                Kompleksowe Zarządzanie Nieruchomościami Daniel Bogdanowicz
                treści dotyczących oferty firmy oraz podmiotów powiązanych na
                wskazany w formularzu kontaktowym numer telefonu zgodnie z art.
                172 ustawy z dnia 16 lipca 2004 roku Prawo telekomunikacyjne
                (Dz. U. z 2019 roku poz. 2460 ze zm.).
              </label>
            </div>

            <input type='submit' value='WYŚLIJ' className='send' />
          </form>
          <div className='card'>
            <h2>Przetwarzanie danych osobowych</h2>
            <p>
              Polityka przetwarzania danych osobowych znajduje się{' '}
              <Link to='/rodo'>TUTAJ</Link>.
            </p>
            <p>
              Administratorem danych osobowych jest Kompleksowe Zarządzanie
              Nieruchomościami Daniel Bogdanowicz z siedzibą w Wałbrzychu, adres
              mailowy do kontaktu w sprawie danych osobowych:
              biuro@nieruchomosci-bogdanowicz.pl
            </p>
          </div>
        </div>
      </div>
    </ContactStyled>
  );
};

export default Contact;
