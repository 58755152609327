/** @format */

import styled from 'styled-components';

const OfferStyled = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin: 16px 0 80px 0; */
  padding-bottom: 100px;
  animation: transitionIn 0.5s;
  @keyframes transitionIn {
    from {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }

  div {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    h1 {
      width: 100vw;
      background-color: var(--bgcgreen);
      color: var(--maincolor);
      padding: 8px 8px;
      text-align: center;
    }
    .image {
      position: relative;
      min-width: 460px;
      min-height: 460px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      box-shadow: 0px 0px 29px -3px rgba(123, 186, 88, 0.52);
      opacity: 0;
    }
    .list {
      width: 50%;
      padding: 0 32px 0 0;
      text-align: justify;

      li {
        position: relative;
        padding: 8px;
        opacity: 0;
      }
    }
    @media (max-width: 975px) {
      .list {
        width: 90vw;
      }
    }
    @media (max-width: 500px) {
      .list {
        text-align: left;
      }
      .image {
        min-width: 300px;
        min-height: 300px;
      }
      h1 {
        font-size: 24px;
      }
    }
  }
`;
export default OfferStyled;
