/** @format */

import styled from 'styled-components';

const SliderImgStyled = styled.div`
  position: fixed;
  bottom: 5vh;
  z-index: 2;

  .next,
  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: black;
    z-index: 10;
    height: 64px;
    width: 64px;
    font-size: 24px;
    cursor: pointer;
  }
  .back {
    left: 0;
  }

  img {
    height: 85vh;
    z-index: 2;
    user-select: none;

    @media (max-width: 440px) {
      height: auto;
      width: 80vw;
    }
  }
  @media (max-width: 440px) {
    bottom: 10vh;
  }
  @media (max-width: 400px) {
    bottom: 15vh;
  }
  @media (max-width: 350px) {
    bottom: 20vh;
  }
`;
export default SliderImgStyled;
