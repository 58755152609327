/** @format */
import data from './Data';
import { NavLink } from 'react-router-dom';

const listItem = data.map((d) => {
  return (
    <li key={d.name}>
      <NavLink activeStyle={{ color: 'var(--bgcgreen)' }} exact to={d.section}>
        {d.name.toLocaleUpperCase()}
      </NavLink>
    </li>
  );
});

const MenuStandard = () => {
  return <>{listItem}</>;
};

export default MenuStandard;
