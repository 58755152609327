/** @format */
import OfferStyled from '../../styled/OfferStyled';
import lease from '../../media/lease.jpg';

const Lease = () => {
  return (
    <div className='lease'>
      <OfferStyled>
        <div>
          <h1>ZARZĄDZANIE NAJMEM PRYWATNYM</h1>
          <div
            className='image animation'
            style={{ backgroundImage: `url(${lease})` }}
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>
                Profesjonalne przygotowanie mieszkania do przekazania do
                zarządzania najmem.
              </li>
              <li>Poszukiwanie i weryfikowanie najemców.</li>
              <li>Przygotowanie kompletnej umowy najmu.</li>
              <li>
                W oparciu o stosowne pełnomocnictwo reprezentowanie właściciela
                wobec najemców, urzędów i instytucji w sytuacjach związanych z
                realizacją umowy o zarządzanie najmem.
              </li>
              <li>Nadzór nad przekazaniem i zdaniem lokalu.</li>
              <li>
                Monitorowanie wpłat i dokonywanie opłat administracyjnych.
              </li>
              <li>Rozliczanie mediów.</li>
              <li>
                Przekazywanie właścicielowi dochodu z najmu wraz z raportem.
              </li>
              <li>Zlecanie napraw i ich nadzór.</li>
              <li>Stały kontakt z najemcą i kontrola lokalu.</li>
              <li>Nadzór nad zmianą najemcy.</li>
              <li>Windykacja przedsądowa należności.</li>
              <li>
                Odbiór lokalu od najemcy oraz rozliczenie kaucji po zakończeniu
                najmu.
              </li>
            </ol>
          </div>
        </div>
      </OfferStyled>
    </div>
  );
};

export default Lease;
