/** @format */

import OfferStyled from '../../styled/OfferStyled';
import clean from '../../media/clean.jpg';

const Clean = () => {
  return (
    <div className='clean'>
      <OfferStyled>
        <div>
          <h1>MYCIE KLATEK SCHODOWYCH</h1>
          <div
            className='image animation'
            style={{ backgroundImage: `url(${clean})` }}
          ></div>
          <div className='list'>
            <ol type='1'>
              <li>Zamiatanie i mycie podłogi.</li>
              <li>Mycie poręczy.</li>
              <li>Mycie drzwi.</li>
              <li>Mycie skrzynek na listy.</li>
              <li>Usuwanie pajęczyn.</li>
              <li>Mycie okien i parapetów.</li>
              <li>Mycie lamp, grzejników i gablot informacyjnych.</li>
            </ol>
          </div>
        </div>
      </OfferStyled>
    </div>
  );
};

export default Clean;
