/** @format */
import React, { useState } from 'react';

import ReferencesStyled from '../../styled/ReferencesStyled';

// import refer1 from '../../media/refer1.png';
import refer0 from '../../media/refer0.png';
import refer10 from '../../media/refer10.png';

import SliderStyled from '../../styled/SliderStyled';
import SliderImgStyled from '../../styled/SliderImgStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const refList = [
  // { src: refer1 },
  { src: refer0 },
  { src: refer10 },
];

const Certificates = () => {
  const [openSlider, setOpenSlider] = useState(false);

  const [indexImg, setIndexImg] = useState();

  const closeSliderHandle = () => {
    setOpenSlider(false);
  };

  //change next img on click on slider
  const changeSlideHandle = () => {
    setIndexImg(Number(indexImg) + Number(1));

    if (indexImg === refList.length - 1) {
      setIndexImg(0);
    }
  };

  const changeSlideHandleBack = () => {
    setIndexImg(Number(indexImg) - Number(1));

    if (indexImg === 0) {
      setIndexImg(refList.length - 1);
    }
  };
  //object closing slider
  const Slider = () => (
    <SliderStyled onClick={closeSliderHandle}>
      <span>
        <FontAwesomeIcon icon={faTimesCircle} />
      </span>
    </SliderStyled>
  );
  //object slider
  const SliderImg = () => {
    return (
      <SliderImgStyled>
        <span className='next' onClick={changeSlideHandle}>
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </span>
        <span className='back' onClick={changeSlideHandleBack}>
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        </span>
        <img
          onClick={changeSlideHandle}
          src={refList[indexImg].src}
          alt='referencje'
        ></img>
      </SliderImgStyled>
    );
  };
  //opening slider after click img from main references page
  const openSliderHandle = (e) => {
    setOpenSlider(true);
    setIndexImg(Number(e.target.attributes[0].value));
  };

  return (
    <>
      {openSlider ? <Slider /> : null}
      {openSlider ? <SliderImg /> : null}

      <ReferencesStyled style={{ minHeight: '0vh' }}>
        {refList.map((item, index) => {
          return (
            <img
              key={index}
              position={index}
              src={item.src}
              onClick={openSliderHandle}
              alt='dokument z referencjami'
            />
          );
        })}
      </ReferencesStyled>
    </>
  );
};

export default Certificates;
