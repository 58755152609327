/** @format */

import styled from 'styled-components';
import logo from '../media/logo.png';

const LogoStyled = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  background-image: url(${logo});
  width: 100px;
  height: 50px;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;
export default LogoStyled;
